import {replayIntegration} from "@sentry/sveltekit";
import {init} from '@jill64/sentry-sveltekit-cloudflare/client';

const onError = init(
    'https://3ca5205ecfa462a8c7889cd31e75843a@sentry.appstractive.com/4',
    {
        sentryOptions: {
            release: "3.0.5",
            sampleRate: 1.0,
            tracesSampleRate: 1.0,
            enableTracing: true,

            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: 0.1,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: 1.0,

            // If you don't want to use Session Replay, just remove the line below:
            integrations: [replayIntegration()],
        },
    }
);

export const handleError = onError((e, sentryEventId) => {
    console.error(e);
})
